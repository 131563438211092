<script>
import urijs from "urijs";
export default {
  computed: {
    list() {
      if (this.$store.state.webUrls.web_landing_page_domain2) {
        let arr = (
          (this.$store.state.webUrls || {}).web_landing_page_domain2 || {}
        ).split(",");
        if(this.$store.getters.getKey('randomArray')=='1'){
          return arr = this.randomArray(arr).slice(0,3)
        }else{
          return arr
        }
        
      } else {
        this.$store.dispatch("getWebUrls");
        return [];
      }
    },
  },
  methods: {
    open(url) {
      const currUrl = urijs(url);
      const protocol = currUrl.protocol();
      if(protocol){
        window.open(url, "_blank”");
      }else{
        window.open(`//${url}`, "_blank”");
      }

    },
    randomArray(arr) {
        return [...arr].sort(function () {
            return (0.5 - Math.random());
        });
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
};
</script>
